@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Raleway:wght@300;500;600;700&display=swap');

.methodology-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 35px;
  color: #011869
}

.methodology-description {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #191919
}

.methodology-section-number {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  opacity: 20%;
  color: #0891C4;
}
.methodology-section-title {
  font-family: Raleway;
  font-weight: 600;
  font-size: 19px;
  color: #011869
}

.methodology-section-text {
  font-family: Raleway;
  font-weight: 300;
  font-size: 16px;
  color: #191919;
  line-height: 30px;
  word-spacing: 2px;
}