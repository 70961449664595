@media (min-width: 768px) {
  .introduction-div {
    background-image: url("../../assets/images/web-introduction-bg.svg");
  }
}

@media (max-width: 768px) {
  .introduction-div {
    background-image: url("../../assets/images/mobile-introduction-bg.svg")
  }
}

@media (min-width: 1500px) {
  .div-svg-video {
    margin-left: 200px;
    margin-top: -100px;
  }
}

@media (min-width: 768px) {
  .div-svg-video {
    margin-top: -100px;
  }
}
iframe {
  clip-path: url(#clip);
  margin-left: 30vw;
  transform: scale(0.7);
  margin-top: 50px;
}